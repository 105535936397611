<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Item
            bladeName="ordering-rules-group"
            navigation="ordering-rules-group" 
            :onNew="onNewItem" 
            @fetched="itemFetched">
            <template slot-scope="{ item, data }">
                <BT-Field-String
                    :isEditing="data.isEditing || data.isNew"
                    v-model="item.groupName"
                    label="Group Name" />
                
                <BT-Sidebar-List
                    v-if="!data.isNew"
                    filterProp="OrderItemRulesGroupID"
                    inline
                    :itemProperties="['ID','Buyer','OrderItemRulesGroupID']"
                    label="Customers"
                    manyNavigation="customers"
                    manyText="buyer.companyName"
                    :searchProps="['buyer.companyName']"
                    :singleID="item.id"
                    singleProp="orderItemRulesGroupID"
                    small />
                    
                <v-container dense>
                    <v-row dense>
                        <v-col dense>Rules <v-btn icon @click="addRule"><v-icon>mdi-plus</v-icon></v-btn></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Ordering-Rule v-for="(itemR, index) in item.orderItemRules" :key="index" :item="itemR" @remove="removeRule(index)"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </BT-Blade-Item>
    </div>
</template>

<script>
export default {
    name: 'Ordering-Rules-Group',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        OrderingRule: () => import('~home/order-rules/Ordering-Rule.vue')
    },
    data: function() {
        return {
            currentItem: null
        }
    },
    methods: {
        onNewItem() { 
            return {
                orderItemRules: []
            }
        },
        itemFetched(item) {
            this.currentItem = item;
        },
        addRule() {
            this.currentItem.orderItemRules.push({
                id: '',
                productID: '',
                rule: 'NoRule',
                quantity: 0,
            });
        },
        removeRule(index) {
            this.currentItem.orderItemRules.splice(index, 1);            
        },
    }
}
</script>